import React from "react";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import Seo from "../components/Seo";

export default ({ data }) => {
  const title = "About";

  return (
    <Layout about>
      <Seo
        title={title}
        description={data.contentfulAbout.description.description}
      />
      <div className="about--text-wrapper">
        <div className="about--text" dangerouslySetInnerHTML={{__html: data.contentfulAbout.description.description}}/>
      </div>
      
    </Layout>
  );
};

export const aboutQuery = graphql`
  query aboutQuery {
    contentfulAbout {
      description {
        childMarkdownRemark {
          html
        }
        description
      }
    }
  }
`;
